import { css as original } from '@custom/defaults/theme/Components/MainMenuItem/styles';
import { primary } from '@brainysoft/lk-custom/colors';
import { merge } from 'lodash';
import { colors } from '../../Styles';

export const css = merge(original, {
  item: {
    '&:hover': {
      background: 'rgb(245, 125, 0)',
    },
    '&.active': {
      background: 'rgb(245, 125, 0)',
    },
    '& .itemText': {
      fontSize: '1.075rem',
      color: 'rgb(57, 50, 0)',
    },
  },
});

export default css;
