import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Divider } from 'antd';
import { OtpChallengeType, PrintFormContextType, PrintFormType } from '@brainysoft/lk-components';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { withStopPropagation } from '../../../hoc/withStopPropagation';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import { useTranslation } from '../../../utils/useTranslation';
import { ChallengeApproval } from '../../../packages/Formik/Components/ChallengeApproval';
import { useClient } from '../../../queries/useClient';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';

export const ProlongationSignDocument: React.FC<IStatefulProcessRenderer> = withStopPropagation((props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('RepaymentPage');
  const form = React.useRef();
  const clientQuery = useClient();

  const { contract, state, goForward, goBack, isMutating } = props;

  const [downloadLink, setDownloadLink] = React.useState('#');
  const [hasDownloadLink, setHasDownloadLink] = React.useState(false);

  const [isCodeFieldLoading, setIsCodeFieldLoading] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickForward = () => {
    (form.current as any).submitForm();
  };

  const onClickBack = async () => {
    await goBack();
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await goForward({
      ...state.data,
      isNeedSaveTimezoneoffsetToExtrafields: true,
      challengeId: values.challengeId,
    });
  };

  const onUpdate = () => {};

  const isSignEnabled = hasDownloadLink && isApproved;

  const onApprove = async () => {
    setIsApproved(true);
  };

  return (
    <React.Fragment>
      <div className={classes.repaymentContentWrapper}>
        <div className={classes.wrapper}>
          <h2 className={classes.header}>{t('repayments:prolongation.changePaymentPeriod')}</h2>
        </div>

        <p>
          Нажмите на кнопку &quot;Получить код&quot; и введите полученный код в поле ниже. Вводя код, вы подтверждаете,
          что ознакомились и согласились с условиями дополнительного соглашения по договору потребительского займа.{' '}
        </p>
        <PrintFormDocument
          name={t(`printForms:name.${PrintFormType.PROLONGATION}`)}
          type={'block'}
          params={{
            clientId: clientQuery.data?.id,
            leadId: contract?.leadId,
            loanAppId: contract?.loanApplicationId,
            contractId: contract?.id,
            variables: {
              prolongationPeriod: state?.data?.period,
              amount: state?.data?.amount,
            },
          }}
          icon={'file-pdf'}
          contextType={PrintFormContextType.RAW}
          printFormType={PrintFormType.PROLONGATION}
          // onClick={handleDownload}
          onDocumentLoadSuccess={async (link) => {
            setHasDownloadLink(true);
            setDownloadLink(link);
          }}
          onDocumentLoadError={() => {
            setHasDownloadLink(false);
          }}
        />
        {hasDownloadLink && (
          <Formik innerRef={form as any} initialValues={{ code: undefined }} onSubmit={onSubmit}>
            {(formik: FormikProps<any>) => {
              return (
                <FormikForm formik={formik} onUpdate={onUpdate} onSubmit={formik.handleSubmit}>
                  <ChallengeApproval
                    formik={formik}
                    idField={'challengeId'}
                    approveField={'signProlongation'}
                    otpChallengeType={OtpChallengeType.PROLONGATION}
                    challengeButtonSize={'middle'}
                    onApprove={onApprove}
                    horizontal={true}
                    mobilePhone={clientQuery.data?.mobilePhone ?? 'undefined'}
                  />
                </FormikForm>
              );
            }}
          </Formik>
        )}
      </div>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={onClickForward}
        onClickBack={onClickBack}
        textForward={t('repayments:prolongation.signDocuments')}
        textBack={t('repayments:prolongation.back')}
        isForwardDisabled={!isSignEnabled}
        isForwardLoading={isLoading}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
});
