import * as defaultComponents from '../default';
import { HeaderPhone } from './HeaderPhone';
import { MessageWidget } from './MessageWidget';
import { ProlongationChooseAmount } from './Prolongation/ProlongationChooseAmount';
import { ProlongationSignDocument } from './Prolongation/ProlongationSignDocument';
import { ContractDetails } from './ContractDetails';
import { LoanAppCreateWidget } from './LoanAppCreateWidget';
import { Favicons } from './Favicons';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...defaultComponents,
  HeaderPhone,
  MessageWidget,
  ProlongationChooseAmount,
  ProlongationSignDocument,
  ContractDetails,
  LoanAppCreateWidget,
  Favicons,
};
