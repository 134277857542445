import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { merge } from 'lodash';
import { colors } from '../../Styles';

export const css = merge(original, {
  header: {
    background: colors.headerBackground,
  },
  phonePlaceholder: {
    color: 'color: rgba(0, 0, 0, 0.85)',
    fontSize: '0.875rem',
  },
  authBackgroundImage: {
    backgroundPosition: 'right top',
  },
});

export default css;
