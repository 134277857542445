import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../utils/useTranslation';
import { get } from 'lodash';
import { CreditProductT, ProcessType } from '@brainysoft/lk-components';
import { useLock } from '../../queries/useLock';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../packages/Formik/Components/FormikForm';
import { Col, Row, Divider, Modal } from 'antd';
import { Button } from '../../components/Button';
import { InterfaceModuleSettingT } from '../../utils/useInterfaceModuleSetting';
import { useInitLoanAppProcess } from '../../utils/useInitLoanAppProcess';
import { useSetting } from '../../utils/useSetting';
import { SettingsType } from '@brainysoft/lk-components';
import { MoneyMethod } from '../../components/MoneyMethod';
import { Message } from '../../components/Message';

export interface ILoanAppCreateWidget {
  widgetSettings: InterfaceModuleSettingT;
  creditProducts: CreditProductT[];
}

export const LoanAppCreateWidget: React.FC<ILoanAppCreateWidget> = (props) => {
  const classes = useThemeStyles('LoanAppCreateWidget');
  const authClasses = useThemeStyles('AuthPage');

  const { creditProducts } = props;

  const moneyMethods = useSetting(SettingsType.GETTING_MONEY_METHOD) ?? [];

  const moneyMethodTypes = moneyMethods.reduce((prev, next) => {
    if (['cash', 'card'].indexOf(next['type']) == -1) return prev;
    if (prev.indexOf(next['type']) == -1) prev.push(next['type']);
    return prev;
  }, []);

  const [selectedMoneyMethod, setSelectedMoneyMethod] = React.useState(undefined);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const selectMethod = (method, formik) => {
    setSelectedMoneyMethod(method);
    const moneyMethodId = moneyMethods.find((e) => e.type === method)?.id;
    formik.setFieldValue('moneyMethod', method);
    formik.setFieldValue('gettingMoneyMethodId', moneyMethodId);
    formik.setFieldTouched('moneyMethod', true);
    setSubmitDisabled(false);
  };
  const { t } = useTranslation();

  // const leadSettings = props.leadSettings;
  const initialCreditProduct = get(creditProducts, '0', {}) as any;
  const initialCreditProductId = initialCreditProduct.id;

  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const { data: lock, isLoading, isFetching } = useLock(ProcessType.LOAN_APP);

  const initialAmount =
    initialCreditProduct.defaultAmount ??
    Math.floor((initialCreditProduct.maxLoanAmount - initialCreditProduct.minLoanAmount) / 2000) * 1000 +
      initialCreditProduct.minLoanAmount;
  const initialPeriod =
    initialCreditProduct.defaultPeriod ??
    Math.floor((initialCreditProduct.maxPeriod - initialCreditProduct.minPeriod) / 2) + initialCreditProduct.minPeriod;

  const initProcess = useInitLoanAppProcess();

  const onSubmit = async (values) => {
    if (values.moneyMethod == 'cash') {
      setShowConfirmModal(true);
      return;
    }

    setIsButtonLoading(true);
    await initProcess(values, initialCreditProduct);
  };

  const confirmRegion = async (values) => {
    setIsButtonLoading(true);
    await initProcess(values, initialCreditProduct);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          amount: initialAmount,
          period: initialPeriod,
          creditProductId: initialCreditProductId,
        }}
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <FormikForm formik={formik} onSubmit={formik.handleSubmit}>
              <h2 className={classes.header} style={{ textAlign: 'center', margin: '2.5rem 0', fontSize: '2rem' }}>
                Выберите способ получения
              </h2>

              <Modal
                centered
                open={showConfirmModal}
                className={authClasses.antdModal}
                closable={false}
                footer={() => (
                  <Row gutter={24} justify='center'>
                    <Col xs={24} md={12}>
                      <Button style={{ width: '100%' }} type='primary' onClick={() => confirmRegion(formik.values)}>
                        Да
                      </Button>
                    </Col>
                    <Col xs={24} md={12}>
                      <Button
                        style={{ width: '100%' }}
                        onClick={(_) => {
                          setShowConfirmModal(false);
                        }}
                      >
                        Нет
                      </Button>
                    </Col>
                  </Row>
                )}
              >
                <Message type={'info'}>
                  <strong style={{ color: 'red' }}>Внимание!</strong> Получение в офисе возможно только для жителей
                  Республики Саха (Якутия). Вы проживаете на территории Республики?
                </Message>
              </Modal>

              <Row gutter={16} justify='center'>
                {moneyMethodTypes.map((method, index) => {
                  return (
                    <Col xs={24} sm={8} key={index}>
                      <MoneyMethod
                        name={t(`moneyMethods:${method}`)}
                        className={selectedMoneyMethod == method ? 'selected moneyMethod' : 'moneyMethod'}
                        onClick={() => {
                          selectMethod(method, formik);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
              {lock?.isLocked && (
                <div className={classes.lockDisclaimer}>Создание заявки временно невозможно, попробуйте позднее</div>
              )}
              <Divider />
              <div className={`${classes.formButtonWrapper} ${classes.mobileButtonWrapper}`}>
                <Button
                  loading={isButtonLoading}
                  type={'primary'}
                  htmlType={'submit'}
                  disabled={isLoading || isFetching || lock?.isLocked || submitDisabled}
                >
                  {t('widgets:loanAppCreate.button')}
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
