import * as React from 'react';

export const Favicons: React.FC = (props) => {
  return (
    <React.Fragment>
      <link rel='apple-touch-icon' sizes='180x180' href='/img/aeb/favicons/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/img/aeb/favicons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/img/aeb/favicons/favicon-16x16.png' />
      <link rel='manifest' href='/img/aeb/favicons/site.webmanifest' />
      <link rel='mask-icon' href='/img/aeb/favicons/safari-pinned-tab.svg' color='#5bbad5' />
      <link rel='shortcut icon' href='/img/aeb/favicons/favicon.ico' />
      <meta name='msapplication-TileColor' content='#006200' />
      <meta name='msapplication-config' content='/img/aeb/favicons/browserconfig.xml' />
      <meta name='theme-color' content='#ffffff' />
    </React.Fragment>
  );
};
