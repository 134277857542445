import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Divider } from 'antd';
import { useProlongationSettings } from '../../../queries/useProlongationSettings';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useTranslation } from '../../../utils/useTranslation';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import Decimal from 'decimal.js-light';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import cls from 'classnames';
import dayjs from 'dayjs';

export const ProlongationChooseAmount: React.FC<IStatefulProcessRenderer> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('RepaymentPage');

  const { contract, state, goForward, isMutating } = props;

  const [minAmount, setMinAmount] = React.useState<Decimal>(new Decimal(0));
  const [amount, setAmount] = React.useState<Decimal>(new Decimal(0));

  const formRef = React.useRef<any>();

  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const prolongationQuery = useProlongationSettings(contract.id);
  const prolongationData = prolongationQuery.data ? prolongationQuery.data[0] : null;

  if (minAmount.isZero() && prolongationData) {
    setMinAmount(new Decimal(prolongationData.amount));
    setAmount(new Decimal(prolongationData.amount));
  }

  const clickNext = () => {
    setButtonsDisabled(true);
    goForward({
      period: prolongationData?.days ?? 0,
      amount: amount.toFixed(2),
    });
    setButtonsDisabled(false);
  };

  const onUpdate = (_, changed) => {
    if (changed?.amount) {
      setAmount(new Decimal(changed.amount));
    }
  };

  return (
    <React.Fragment>
      <div className={cls(classes.repaymentContentWrapper, classes.wrapper)}>
        <h2 className={classes.header}>{t('prolongation:changePeriod')}</h2>
        {(prolongationQuery.isLoading || prolongationQuery.isFetching) && (
          <div className={classes.loaderWrapper}>
            <LoadingSpinner size={8} margin={4} />
          </div>
        )}

        {!(prolongationQuery.isLoading || prolongationQuery.isFetching) && (
          <React.Fragment>
            <p>
              1) Условия дополнительного соглашения:
              <br />
              1.1) Срок оплаты
              <br />
              {prolongationData?.days} дней
              <br />
              1.2) Процентная ставка:
              <br />
              {contract.interestForTranche}% в день
              <br />
              1.3) Новый срок оплаты:
              <br />
              {prolongationData?.date ? dayjs(prolongationData.date).format('DD.MM.YYYY') : ''}
            </p>
            <p>
              Для того, чтобы отложить оплату по займу Вам
              <br />
              необходимо внести сумму не менее {minAmount.toFixed(2)} руб.
              <br />
              (Сумма начисленных % и штрафов на текущую дату)
              <br />
            </p>
            <Formik enableReinitialize={true} onSubmit={() => {}} initialValues={{ amount }} innerRef={formRef as any}>
              {(formik: FormikProps<any>) => {
                return (
                  <FormikForm formik={formik} onUpdate={onUpdate} onSubmit={formik.handleSubmit}>
                    <div className='field'>
                      <MaskedInput name='amount' mask='currency' required={true} shouldValidateOnChange={true} />
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </React.Fragment>
        )}
      </div>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={clickNext}
        textForward={t('prolongation:forward')}
        isForwardDisabled={amount.isZero() || amount.lt(minAmount) || buttonsDisabled}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
};
