import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyText, primary, white } from '../../../colors';
import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/HeaderPhone/styles';

export const css = merge(original, {
  phoneWrap: {
    '&.inside': {
      '& $phoneNumber': {
        fontSize: '1rem',
        color: 'rgb(57, 50, 0)',
      },
      '& $phoneDetail': {
        color: 'rgb(57, 50, 0)',
      },
    },
  },
  phoneNumber: {
    fontSize: '1.125rem',
  },
  phoneDetail: {
    marginTop: 0,
    marginBottom: '.25rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    phoneNumber: {
      fontSize: '0.8125rem',
    },
    phoneDetail: {
      fontSize: '0.625rem',
    },
    phoneWrap: {
      display: 'none',
    },
  },
});

export default css;
