import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { topBarHeight, topBarHeightMobile } from '../../../variables';
import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/Logo/styles';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = merge(original, {
  logoWrapper: {
    height: `calc(${topBarHeight} - 4rem)`,
    marginLeft: '.25rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    logo: {
      fontSize: '1.25rem',
      width: '6.25rem',
      height: '2.125rem',
      lineHeight: '2.125rem',
    },
    logoImg: {
      height: 'auto',
      width: 'auto',
      maxWidth: '10rem',
      marginRight: '0rem',
    },
    logoWrapper: {
      height: `calc(${topBarHeightMobile} - 2rem)`,
    },
  },
});

export default css;
