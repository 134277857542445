import { css as original } from '@custom/defaults/theme/Components/HeaderRegistration/styles';
import { merge } from 'lodash';
import { colors } from '../../Styles';

export const css = merge(original, {
  headerWrap: {
    background: colors.headerBackground,
  },
});

export default css;
