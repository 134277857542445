import headerRu from './translations/ru/header.json';
import printFormsRu from './translations/ru/printForms.json';
import widgetsRu from './translations/ru/widgets.json';
import repaymentsRu from './translations/ru/repayments.json';
import contractRu from './translations/ru/contract.json';
import processesRu from './translations/ru/processes.json';

export const translations = {
  ru: {
    header: headerRu,
    printForms: printFormsRu,
    widgets: widgetsRu,
    repayments: repaymentsRu,
    contract: contractRu,
    processes: processesRu,
  },
};
