import { css as original } from '@custom/defaults/theme/Components/Header/styles';
import { merge } from 'lodash';
import { colors } from '../../Styles';

export const css = merge(original, {
  header: {
    background: colors.headerBackground,
  },
});

export default css;
