import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import { phoneRaw } from '../../helpers';
import { HEADER_INVERTED } from '@brainysoft/lk-custom/config';

interface HeaderPhoneProps {
  inside?: boolean;
  inverted?: boolean;
}

export const HeaderPhone: React.FC<HeaderPhoneProps> = (props) => {
  const classes = useThemeStyles('HeaderPhone');
  const { tAsArray } = useTranslation();

  const { inside } = props;

  const descriptionAuth = tAsArray('header:phoneDescriptionAuth').map((text, index) => <div key={index}>{text}</div>);

  const description = tAsArray('header:phoneDescription').map((text, index) => <div key={index}>{text}</div>);

  const configPhones = ['8 924 766 4008', '8 924 766 4060'];
  const descriptions = ['Будни с 9:00 до 17:00 время якутское', 'Ежедневно с 10:00 до 20:00 время якутское'];

  const telephones = configPhones.map((phone) => {
    return {
      formatted: phone,
      raw: phoneRaw(phone),
    };
  });

  const insideClass = inside ? 'inside' : '';
  const invertedClass = HEADER_INVERTED ? 'inverted' : '';

  return (
    <div className={`${classes.phoneWrap} ${insideClass}`}>
      <div className={classes.phoneBlockWrapper}>
        {telephones.length &&
          telephones.map((telephone, index) => {
            return (
              <React.Fragment key={index}>
                <a className={`${classes.phoneNumber} ${invertedClass}`} href={`tel:+${telephone.raw}`}>
                  {telephone.formatted ?? ''}
                </a>
                <div className={`${classes.phoneDetail} ${invertedClass}`}>{descriptions[index]}</div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};
