import * as React from 'react';
import { useRouter } from 'next/router';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import { useActiveCredit } from '../../queries/useActiveCredit';
import { CreditStatusType } from '@brainysoft/lk-components';

export const MessageWidget: React.FC = () => {
  const classes = useThemeStyles('MessageWidget');
  const { tAsArray, keyExists } = useTranslation();
  const { route, query } = useRouter();
  const activeCreditQuery = useActiveCredit();

  if (route !== '/contracts/[id]' || activeCreditQuery.isLoading) return null;
  const contractId = Number(query.id);
  const credit = activeCreditQuery.data;
  if (credit.status !== CreditStatusType.ISSUED || credit.entities.contractId !== contractId) return null;

  return (
    <React.Fragment>
      {keyExists('widgets:message') && (
        <div className={classes.messageWrapper}>
          <span className={classes.message}>
            {tAsArray('widgets:message').map((mess, i) => (
              <p key={i}>{mess}</p>
            ))}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};
