export { css as HeaderPhone } from './HeaderPhone/styles';
export { css as HeaderRegistration } from './HeaderRegistration/styles';
export { css as MainMenuItem } from './MainMenuItem/styles';
export { css as Header } from './Header/styles';
export { css as Logo } from './Logo/styles';
export { css as AuthPage } from './AuthPage/styles';
export { css as ActiveCreditPage } from './ActiveCreditPage/styles';

export {
  //ActiveCreditPage,
  //AuthPage,
  Avatar,
  Button,
  Card,
  ClientDataUpdatePage,
  Collapse,
  ContractInfo,
  ContractPage,
  ContractsPage,
  CreditChart,
  CreditProducts,
  CreditTable,
  CurrentStatus,
  Disbursement,
  DisclaimerWidget,
  Documents,
  DocumentsPage,
  ExternalAuth,
  Footer,
  //Header,
  HeaderAuth,
  // HeaderPhone,
  // HeaderRegistration,
  Icon,
  Icons,
  IndexPage,
  Layout,
  LoadingSpinner,
  LoanAppCreatePage,
  LoanAppCreateWidget,
  LoanAppListPage,
  LoanAppPage,
  // Logo,
  MainMenu,
  //MainMenuItem,
  Message,
  MessageWidget,
  Modal,
  MoneyMethod,
  Pagination,
  PaymentsPage,
  PaymentsTable,
  PhoneInput,
  Price,
  PrintForm,
  PrintFormDocument,
  Process,
  ProfileMenu,
  ProfilePage,
  ProfileWidget,
  RegistrationPage,
  RepaymentPage,
  RepaymentWidget,
  RequestButton,
  ResultIcon,
  ScheduleTable,
  SMSProof,
  Start,
  StatefulProcess,
  Steps,
  Subdivision,
  Survey,
  Tag,
  ListOfAvailableRegions,
} from '@custom/defaults/theme/Components';
