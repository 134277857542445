import { css as original } from '@custom/defaults/theme/Components/ActiveCreditPage/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-form-item': {
      marginBottom: '0 !important',
    },
    marginBottom: '.5rem',
  },
  checkboxLabel: {
    height: 'auto',
    minHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    marginLeft: '1rem',
  },
});

export default css;
